import { BeeModel } from '../abstract';

export class Product extends BeeModel {
  name: string;
  defaultPrice: string;
  pivot: any;
  imageUrl: string;
  productCategory: string;
  productCategoryId: any;
  price: string;
}
